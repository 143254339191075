<template>
  <div>
    <b-sidebar
      id="sidebar-events-upload"
      sidebar-class="sidebar-lg"
      :visible="isEventsUploadSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-events-upload-sidebar-active', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">{{ $t('bulkEventsUpload') }}</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="uploadEventsForm">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.upload_start_date')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.upload_start_date')"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="projectStartDate"
                  class="form-control"
                  :config="datePickerConfig"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Filter by -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.filter_by')"
              rules="required"
            >
              <b-form-group :label="$t('label.filter_by')" label-for="workers">
                <v-select
                  v-model="filterBy"
                  :reduce="(filterOptions) => filterOptions.value"
                  label="title"
                  :options="filterOptions"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Workers -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.workers')"
              rules="required"
              v-if="filterBy === 'workers'"
            >
              <b-form-group :label="$t('label.workers')" label-for="workers">
                <v-select
                  v-model="selectedWorkers"
                  :reduce="(workersSupervised) => workersSupervised.value"
                  :multiple="true"
                  :close-on-select="false"
                  label="title"
                  :options="workersSupervised"
                  :placeholder="$t('placeholder.workers')"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Roles -->
            <b-form-group v-if="filterBy === 'roles/locations'" :label="$t('label.Roles')" label-for="roles">
              <v-select
                v-model="selectedRoles"
                :reduce="(roles) => roles.value"
                :multiple="true"
                :close-on-select="false"
                label="title"
                :options="roles"
                :placeholder="$t('placeholder.roles')"
              />
            </b-form-group>

            <!-- Locations -->
            <b-form-group v-if="filterBy === 'roles/locations'" :label="$t('label.Locations')" label-for="locations">
              <v-select
                v-model="selectedLocations"
                :reduce="(locations) => locations.value"
                :multiple="true"
                :close-on-select="false"
                label="title"
                :options="locations"
                :placeholder="$t('placeholder.locations')"
              />
            </b-form-group>

            <!-- Include weekends -->
            <b-form-group label="" label-for="weekend">
              <b-form-checkbox
                id="checkbox-1"
                v-model="includeWeekends"
                name="checkbox-1"
                :value="true"
                :unchecked-value="false"
              >
                {{ $t('label.allow_events_weekend') }}
              </b-form-checkbox>
            </b-form-group>
            
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="info"
                class="mr-2"
                :disabled="isSubmitting"
                @click="validateAndOpenModal()"
              >
                {{ $t('message.upload_events') }}
              </b-button>
              <b-modal
                id="modal-upload-events"
                ref="uploadEventsModal"
                centered
                no-close-on-backdrop
                modal-class="modal-info"
                ok-variant="info"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.upload')"
                :cancel-title="$t('message.cancel')"
                @ok="uploadEvents"
              >
                {{ $t('message.confirm_events_upload') }}
              </b-modal>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs, onMounted } from "@vue/composition-api";
import useEventsUpload from "./useEventsUpload";
import useCommon from "@/views/organization/useCommon";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BModal,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: "isEventsUploadSidebarActive",
    event: "update:is-events-upload-sidebar-active",
  },
  props: {
    isEventsUploadSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    };
  },
  setup(props, { emit }) {
    const {
      getWorkersSupervisedForDropDown,
      workersSupervised,
      getRolesForDropDown,
      roles,
      getLocationsForDropDown,
      locations,
    } = useCommon();

    onMounted(async () => {
      await getWorkersSupervisedForDropDown();
      await getRolesForDropDown();
      await getLocationsForDropDown();
    });

    const {
      // UI
      bulkEventsUpload,
      projectStartDate,
      filterBy,
      filterOptions,
      selectedWorkers,
      selectedRoles,
      selectedLocations,
      includeWeekends,
    } = useEventsUpload(toRefs(props), emit);

    const { getValidationState } = formValidation();
    const { showSuccessMessage, showErrorMessage } = useNotifications()

    const isSubmitting = ref(false)
    const uploadEventsForm = ref(null)
    const uploadEventsModal = ref(null)
    const datePickerConfig = ref({ minDate: "today" })

    const validateForm = () => new Promise((resolve, reject) => {
      uploadEventsForm.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndOpenModal = () => {
      if (filterBy.value === "roles/locations" && !selectedRoles.value.length && !selectedLocations.value.length) {
        showErrorMessage(i18n.t('message.mustSelectRoleOrLocation'))
        return
      }
      validateForm()
        .then(() => uploadEventsModal.value.show())
        .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    const resetForm = () => {
      projectStartDate.value = new Date()
      includeWeekends.value = false
      filterBy.value = "workers"
      selectedWorkers.value = []
      selectedRoles.value = []
      selectedLocations.value = []
      uploadEventsForm.value.reset()
    }

    const uploadEvents = async () => {
      try {
        isSubmitting.value = true
        await bulkEventsUpload([...selectedWorkers.value])
      } catch (error) {
        console.log(error)
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      getValidationState,
      workersSupervised,
      roles,
      locations,
      uploadEvents,
      isSubmitting,
      datePickerConfig,
      projectStartDate,
      filterBy,
      filterOptions,
      selectedWorkers,
      selectedRoles,
      selectedLocations,
      includeWeekends,
      validateAndOpenModal,
      uploadEventsForm,
      uploadEventsModal,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>