<template>
  <b-card>
    <b-row>
      <b-col md="4" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'organization-confirmation-planning-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Confirmation_Planning') }}
        </b-button>
      </b-col>

      <b-col md="4"  class="pb-1">
        <b-button
          variant="outline-info"
          @click="isEventsUploadSidebarActive = true"
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          {{ $t('bulkEventsUpload') }}
        </b-button>
      </b-col>

      <b-col md="4">
        <b-form-group>
          <b-form-input
            v-model="searchQuery"
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>

        <b-col>
          <filters 
            :roleFilterShow="true"
            :processFilterShow="true"
            @changeFilter="updateFilter"
          />
        </b-col>

      <b-col cols="12">
        <b-table
          ref="refConfirmationPlanningTable"
          :items="confirmationPlannings"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(roleName.name)="data">
            {{ data.item.roleName[0].name }}
          </template>

          <template #cell(processName.name)="data">
            {{ data.item.processName[0].name }}
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <!-- <ViewButton /> -->
              <EditButton @clicked="router.push({name: 'organization-confirmation-planning-edit', params: { id: data.item._id }})"/>
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteConfirmationPlanning(data.item._id, deletedSuccess)"
              >
                {{ $t('message.confirm_delete_confirmation_planning') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5, 10, 15, 20, 25, 30, 40, 50, 100]"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ $tc('message.paginationText', 0, { from: `${((currentPage * perPage) - perPage) + (confirmationPlannings.length ? 1 : 0)}`, to: `${confirmationPlannings.length + ((currentPage * perPage) - perPage)}`, total: `${totalConfirmationPlannings}` }) }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalConfirmationPlannings"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
    
    <!-- Sidebar Overlay: Bulk Events Upload -->
    <div
      class="body-content-overlay"
      :class="{'show': isCalendarOverlaySidebarActive}"
      @click="isCalendarOverlaySidebarActive = false"
    />
    <events-upload
      v-model="isEventsUploadSidebarActive"
    />
  </b-card>
</template>

<script>
import { BBadge, BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, onMounted, ref } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'

import useNotifications from '@/composables/useNotifications'
import useConfirmationPlanningList from './useConfirmationPlanningList'

import confirmationPlanningStoreModule from '../confirmationPlanningStoreModule'
import i18n from '@/libs/i18n/index.js'
import Filters from "@/views/organization/Filters.vue";
import EventsUpload from '../events-upload/EventsUpload.vue'

export default {
  components: {
    BTable,
    BBadge,
    BPagination,
    EditButton,
    ViewButton,
    DeleteButton,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Filters,
    BModal,
    EventsUpload,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      items: [],
    }
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const ORGANIZATION_CONFIRMATION_PLANNING_STORE_MODULE_NAME = 'organization-confirmation-planning'
    const { router } = useRouter()
    const {
      fetchConfirmationPlannings,
      tableColumns,
      perPage,
      currentPage,
      totalConfirmationPlannings,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      deleteConfirmationPlanning,
      refConfirmationPlanningTable,
      confirmationPlannings,
      processFilter,
      roleFilter,
      isLoading,
    } = useConfirmationPlanningList()
    const isEventsUploadSidebarActive = ref(false)
    const isCalendarOverlaySidebarActive = ref(false)

    // Register module
    if (!store.hasModule(ORGANIZATION_CONFIRMATION_PLANNING_STORE_MODULE_NAME)) {
      store.registerModule(
        ORGANIZATION_CONFIRMATION_PLANNING_STORE_MODULE_NAME,
        confirmationPlanningStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_CONFIRMATION_PLANNING_STORE_MODULE_NAME)) { store.unregisterModule(ORGANIZATION_CONFIRMATION_PLANNING_STORE_MODULE_NAME) }
    })

    function updateFilter(data) {
      processFilter.value = data.processFilter;
      roleFilter.value = data.roleFilter;

      fetchConfirmationPlannings();
    }

    onMounted(async () => {
      fetchConfirmationPlannings()
    })

    const deletedSuccess = success => {
      if (success) {
        fetchConfirmationPlannings()
        showSuccessMessage(i18n.t('message.confirmation_planning_remove'))
      } else {
        showErrorMessage(i18n.t('message.something_went_wrong'))
      }
    }

    return {
      router,
      fetchConfirmationPlannings,
      tableColumns,
      perPage,
      currentPage,
      totalConfirmationPlannings,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      deleteConfirmationPlanning,
      deletedSuccess,
      refConfirmationPlanningTable,
      confirmationPlannings,
      updateFilter,
      isEventsUploadSidebarActive,
      isCalendarOverlaySidebarActive,
      isLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
